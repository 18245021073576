<template>
  <div class="avatar">
    <template v-if="!token">
      <NuxtLink
        v-if="!nav || nav.userLogin"
        :to="'/login?redirect=' + encodeURIComponent(routePath)"
        class="handle_bar_item"
      >
        <span> 登录 </span>
      </NuxtLink>
      <NuxtLink
        v-if="nav && nav.userRegister && userStore.shopData && userStore.shopData.config?.allowUserRegister"
        to="/register"
        class="handle_bar_item"
      >
        <span> 注册 </span>
      </NuxtLink>
    </template>
    <client-only v-if="token">

      <div style="margin-right: 20px;display: flex;align-items: center;cursor: pointer" v-if="config.isShowShoppingCart" @click="onShopping">
        <el-badge :value="cartNum" class="item" v-if="cartNum" style="display: flex">
          <img src="@/assets/images/new/cart@2x.png" style="width: 19px;height: 18px" />
          <!--          <el-icon color="#606266" size="20" style="font-weight: bold;cursor: pointer" @click="onShopping"><ShoppingCart /></el-icon>-->
        </el-badge>
        <img v-else src="@/assets/images/new/cart@2x.png" style="width: 19px;height: 18px"/>
        <!--        <el-icon v-else color="#606266" size="20" style="font-weight: bold;cursor: pointer" @click="onShopping"><ShoppingCart /></el-icon>-->
      </div>

      <el-dropdown
        v-if="!userView.teacher"
        size="large"
      >
        <span
          v-if="!userView.teacher"
          class="handle_bar_item"
        > 入驻 </span>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item>
              <NuxtLink to="/apply/teacher">
                教师入驻
              </NuxtLink>
            </el-dropdown-item>
            <el-dropdown-item>
              <span @click="ctrl.teacherApplyVisible = true"> 申请记录 </span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
      <NuxtLink
        v-else
        to="/teacher"
        target="_blank"
        class="handle_bar_item"
      >
        <span> 教师中心 </span>
      </NuxtLink>
      <NuxtLink
        v-if="!userView.isVip && nav?.vip && userStore.shopData?.config?.vipEnableMarketing"
        to="/vip"
        class="handle_bar_item"
      >
        <span> 开通VIP </span>
      </NuxtLink>

      <el-popover
        key="head_popover"
        placement="bottom"
        trigger="hover"
        :teleported="false"
        popper-class="nav_popover"
        :width="310"
      >
        <template #reference>
          <NuxtLink
            to="/account/course"
            class="handle_bar_item handle_bar_item_last"
          >
            <el-avatar
              class="avatar_img"
              :size="30"
              :src="userView.headImg"
            >
              <img
                src="https://cube.elemecdn.com/e/fd/0fc7d20532fdaf769a25683617711png.png"
              >
            </el-avatar>
            <img
              v-if="userView.isVip"
              class="vip_icon"
              src="@/assets/pc/icon_vip.png"
              alt=""
            >
          </NuxtLink>
        </template>
        <div class="user_info_box">
          <div class="user_info">
            <NuxtLink to="/account/info">
              <el-avatar
                class="user_info_avatar_img"
                :size="60"
                :src="userView.headImg"
              >
                <img
                  src="https://cube.elemecdn.com/e/fd/0fc7d20532fdaf769a25683617711png.png"
                >
              </el-avatar>
            </NuxtLink>
            <h5 class="user_info_name">
              {{ userView.nickname }}
            </h5>
            <p class="user_info_phone">
              {{ userView.phone ? userView.phone.replace(/^(\d{3})\d{4}(\d{4})$/, "$1****$2") : '' }}
            </p>
          </div>
          <ul class="user_btn_list">
            <NuxtLink
              to="/account/course"
              class="user_btn"
            >
              <li>
                <i class="iconfont icon-icon_kecheng" />
                我的课程
              </li>
            </NuxtLink>
            <NuxtLink
              to="/account/note"
              class="user_btn"
            >
              <li>
                <i class="iconfont icon-icon_Edit" />
                我的笔记
              </li>
            </NuxtLink>
            <!--            <NuxtLink-->
            <!--              to="/account/money"-->
            <!--              class="user_btn"-->
            <!--            >-->
            <!--              <li>-->
            <!--                &lt;!&ndash; <i class="iconfont icon-icon_Edit" /> &ndash;&gt;-->
            <!--                推广赚钱-->
            <!--              </li>-->
            <!--            </NuxtLink>-->
            <NuxtLink
              to="/account/certificate"
              class="user_btn"
            >
              <li>
                <i class="iconfont icon-icon_Certificate" />
                我的证书
              </li>
            </NuxtLink>
            <NuxtLink
              to="/account/order"
              class="user_btn"
            >
              <li>
                <i class="iconfont icon-dingdan" />
                我的订单
              </li>
            </NuxtLink>
          </ul>
          <button
            class="out_btn"
            @click="userOut()"
          >
<!--            <i class="iconfont icon-tuichu" />-->
            安全退出
          </button>
        </div>
      </el-popover>
    </client-only>
    <teacher-apply-record
      v-model="ctrl.teacherApplyVisible"
      @close="ctrl.teacherApplyVisible = false"
    />
  </div>
</template>

<script setup>
import { useNavStore } from '@/stores/nav'
import { useUserStore } from '@/stores/user'
import { setSession } from '@/utils/storage'
import { getPromoterConfig } from '@/api/promoter'
import TeacherApplyRecord from './record.vue'
import { userLogOut } from '@/api/user.js'
import {computed} from "vue";
import { useCartStore } from '@/stores/cart'
import { ShoppingCart } from '@element-plus/icons-vue'
import config from '@/config/index'
const cartStore = useCartStore()
const cartNum = computed(() => cartStore.cartNum)
const route = useRoute()
const router = useRouter()
const store = useNavStore()
const userStore = useUserStore()
const { setToken, setUserView, getPromoterConfigs, getToken, removeToken } = userStore

userStore.getUserView()
const nav = store.nav
const token = computed(() => userStore.token)
const userView = computed(() => userStore.userView)
const routePath = ref(route.fullPath)
router.beforeResolve((to) => {
  routePath.value = to.fullPath
})

const ctrl = reactive({
  teacherApplyVisible: false
})

const onShopping = () => {
  router.push({path:'/shopping/trolley'})
}

const userOut = () => {
  userLogOut().then(res => {
    if (res) {
      // setToken('')
      removeToken()
      setUserView('')
      setSession('userInfo', '')
      window.location.href = '/home'
    }
  })
}
const enablePromoter = ref()
const promoterConfig = computed(() => store.promoterConfig)
if (!promoterConfig.value) {
  getPromoterConfig().then(res => {
    enablePromoter.value = res.config.enablePromoter
    getPromoterConfigs(res)
  })
}
</script>

<style lang="scss" scoped>
.avatar {
  display: flex;
  align-items: center;

}
.handle_bar_item {
  cursor: pointer;
  color: white;
  margin-right: 20px;
  white-space: nowrap;
  position: relative;
  font-size: 14px;
  &_last {
    margin-right: 0;
  }

  &:active {
    color: var(--el-color-primary);
  }

  &:focus {
    outline: none;
  }

  &:last-child{
    margin-right: 0;
  }

  &:first-child{
    margin-right: 20px;
  }

  .vip_icon {
    position: absolute;
    bottom: 2px;
    right: -2px;
    width: 14px;
    height: 14px;
  }
}

.avatar_img {
  margin-top: 6px;
}

.user_info_box {
  width: 310px;
  height: 275px;
  background: #FFFFFF;
  box-shadow: 0px 5px 15px rgba(54, 41, 183, 0.06);
  border-radius: 0px 0px 5px 5px;
  box-sizing: border-box;
  padding: 20px;

  .out_btn {
    margin-top: 15px;
    border-top: 1px solid #EBEEF5;
    padding: 12px 0 16px;
    width:100%;
    text-align: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #F61818;
    .iconfont {
      font-size: 20px;
      vertical-align: -2.5px;;
    }
  }

  .user_btn_list {
    font-size: 0px;
    margin-top: 16px;
    .user_btn {
      width: 130px;
      height: 50px;
      text-align: center;
      background: #F6F8FB;
      border-radius: 5px;
      display: inline-block;
      margin: 0 10px 10px 0;
      font-size: 14px;
      color: #333333;
      line-height: 50px;

      &:nth-child(2n) {
        margin-right: 0px;
      }
      .iconfont {
        font-size: 20px;
        vertical-align: -3px;;
      }
    }
  }
  .user_info {
    padding-left: 75px;
    position: relative;
    height: 60px;
    .user_info_avatar_img {
      position: absolute;
      left: 0;
      top: 0;
    }

    .user_info_name {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      padding-top: 8px;
      color: #333333;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .user_info_phone {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      margin-top: 6px;
      color: #333333;
    }

  }
}

</style>

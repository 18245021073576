<template>
  <div class="bottom-bg-view">
    <div class="img-box w">
      <img src="@/assets/images/new/bg.png"/>
    </div>

  </div>
</template>

<script>
export default {
  name: "index"
}
</script>

<style scoped lang="scss">
.bottom-bg-view{
  .img-box{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 140px;
    img{
      width: 626px;
      height: 140px;
    }
  }
}
</style>
